import AppRoute from "./routes/AppRoute";
import PlanState from "./contexts/Plans/planState";
import CustomerState from './contexts/Customers/customerState'
import AuthState from "./contexts/Auth/authState";
import UserState from "./contexts/Users/userState";

function App () {
  return (
    <AuthState>
      <UserState>
        <CustomerState>
          <PlanState>
            <AppRoute />
          </PlanState>
        </CustomerState>
      </UserState>
    </AuthState>
  );
}

export default App;
