import React, { useContext, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Input, Button, Card, Flex, PasswordInput } from "@mantine/core";
import md5 from "md5";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import AuthContext from "../../contexts/Auth/authContext";
import ToggleButton from "../../components/ToggleButton";
import Logo from "../../assets/logo.png";
import { DASHBOARD } from "../../routes/types";

const Login = () => {
  const navigate = useNavigate();
  const { login, autenticado } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("El usuario es obligatorio"),
      password: Yup.string()
        .min(4, "La contraseña debe tener al menos 6 caracteres")
        .max(18, "La contraseña no puede exceder los 18 caracteres")
        .required("La contraseña es obligatoria"),
    }),
    onSubmit: async (formData) => {
      const data = {
        username: formData.username,
        password: md5(formData.password),
      };
      await login(data);
    },
  });

  useEffect(() => {
    if (autenticado) {
      navigate(DASHBOARD);
    }
  }, [autenticado, navigate]);

  return (
    <Box className="w-full">
      <Box className="flex flex-row justify-end">
        <ToggleButton />
      </Box>

      <Flex justify="center" align="center" className="w-full gap-1">
        <Card shadow="sm" padding="xl" radius="xl" withBorder mx="md" maw={400}>
          <Flex direction="column" gap="xs" px="md" py="xs">
            <Box className="flex flex-col items-center justify-center">
              <img
                src={Logo}
                alt="Logo GC"
                className="w-48 bg-white rounded-full border-2"
              />
            </Box>

            <form onSubmit={formik.handleSubmit} className="w-full">
              <Text align="center" size="xl" weight={700}>
                ADMIN PANEL
              </Text>

              <FormInput
                label="Usuario"
                placeholder="Usuario"
                value={formik.values.username}
                error={formik.touched.username && formik.errors.username}
                onChange={formik.handleChange("username")}
              />

              <FormInput
                label="Contraseña"
                placeholder="Contraseña"
                type="password"
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange("password")}
              />

              <Button
                my="md"
                fullWidth
                variant="filled"
                color="yellow"
                type="submit"
              >
                Iniciar sesión
              </Button>
            </form>
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
};

export default Login;

const FormInput = ({ label, placeholder, type = "text", value, error, onChange }) => (
  <Box my="sm">
    <Input.Wrapper label={label} w="100%">
      {type === "password" ? (
        <PasswordInput
          variant="filled"
          size="lg"
          radius="md"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      ) : (
        <Input
          variant="filled"
          size="lg"
          radius="md"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
      {error && <Text color="red" size="xs">{error}</Text>}
    </Input.Wrapper>
  </Box>
);
