/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header";
import AuthContext from "../contexts/Auth/authContext";
import { AppShell, ScrollArea, NavLink, Box } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { HiOutlineLogout } from "react-icons/hi";
import ToogleButton from "../components/ToggleButton";
import useIsMobileOrTablet from "../hooks/useIsMobileorTablet";

const PrivateRoute = ({ children }) =>
{
  const { token, logout } = useContext(AuthContext);
  const [ opened, { toggle } ] = useDisclosure();
  const isMobileOrTablet = useIsMobileOrTablet();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <AppShell

      header={ { height: 60, collapsed: !isMobileOrTablet } }
      navbar={ {
        width: 180,
        breakpoint: "md",
        collapsed: { mobile: !opened },
      } }
      padding="sm"
    >
      <AppShell.Header>
        <Header opened={ opened } toggleSidebar={ toggle } />
      </AppShell.Header>

      <AppShell.Navbar>
        <AppShell.Section grow my="md" component={ ScrollArea }>
          <Sidebar toggleSidebar={ toggle } />
        </AppShell.Section>

        <AppShell.Section>
          <Box className="py-2 px-2 w-full">
            <ToogleButton />
            <NavLink
              onClick={ logout }
              label="Cerrar sesión"
              leftSection={ <HiOutlineLogout className="text-md lg:text-lg" /> }
            />
          </Box>
        </AppShell.Section>
      </AppShell.Navbar>

      <AppShell.Main>{ children }</AppShell.Main>
    </AppShell>
  );
};

export default PrivateRoute;
