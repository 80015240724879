import React, { useContext } from "react";
import { currencyFormat } from "simple-currency-format";
import dayjs from "dayjs";
import { Card, Text, Grid, Box, Flex, Image, ActionIcon, Tooltip } from "@mantine/core";

import Banregio from "../../assets/banks/banregio.webp";
import Azteca from "../../assets/banks/azteca.webp";
import Bbva from "../../assets/banks/bbva2.png";
import Hsbc from "../../assets/banks/hsbc.png";
import MercadoPago from "../../assets/banks/mercado-pago.webp";
import Santander from "../../assets/banks/santander-logo-1.webp";
import Efectivo from "../../assets/banks/efectivo.png";
import Spin from "../../assets/banks/spin.png";
import { HiTrash } from "react-icons/hi";
import AuthContext from "../../contexts/Auth/authContext";
const bankImages = {
  "Mercado Pago": MercadoPago,
  BBVA: Bbva,
  Santander: Santander,
  Hsbc: Hsbc,
  Banregio: Banregio,
  Azteca: Azteca,
  Efectivo: Efectivo,
  Spin: Spin,
};

const Record = ({ item, rollBackRenovation }) =>
{
  const { username } = useContext(AuthContext);
  const renderBankImage = (bankName) => (
    <Box className="w-16 h-4">
      <Image
        src={ bankImages[ bankName ] }
        alt={ bankName }
        title={ bankName }
        className="w-auto h-auto"
      />

    </Box>
  );

  const renderGridContent = () => (
    <Grid p="xs" align="center">
      {/* <Grid.Col span={ { base: 6, lg: 3 } } className=" xl:block">
        <Text size="xs" >{ dayjs(item?.created_date).format("DD-MMM") }</Text>
      </Grid.Col> */}
      <Grid.Col span={ { base: 6, lg: 4 } } className=" xl:block">
        <Text size="xs" >{ item.type_contract }</Text>
      </Grid.Col>
      <Grid.Col span={ { base: 8, lg: 4 } }>
        <Text size="xs" >{ item.num_account }</Text>
      </Grid.Col>
      <Grid.Col span={ { base: 4, lg: 4 } }>
        <Text size="xs" align="right" weight={ 600 }>
          { currencyFormat(item.amount, "es-MX", "MXN", 2) }
        </Text>
      </Grid.Col>
    </Grid>
  );

  const renderRollBackAction = () => (
    (username === 'edgar' || username === 'jvaldes') ? (
      <Box>
        <Tooltip label="Revertir renovación">
          <ActionIcon variant="subtle" color="red" onClick={ () => rollBackRenovation(item) } className="absolute top-0 right-0" padding="xs">
            <HiTrash />
          </ActionIcon>
        </Tooltip>
      </Box>
    ) : null
  )

  return (
    <Card shadow="sm" padding="xl" radius="md" withBorder mx="md">
      <Card.Section pt="xs" px="md">
        <Box className="flex items-center justify-end xl:hidden">
          { renderRollBackAction() }
        </Box>
        <Flex justify="space-between" align="center">
          { renderBankImage(item.type_bank_name) }
          <Box className="hidden xl:flex flex-row items-center">
            { renderRollBackAction() }
          </Box>
          <Box >
            <Text size="xs">{ dayjs(item?.created_date).format("DD-MM") }</Text>
          </Box>
          {/* <Text className="block xl:hidden">{ item.type_contract }</Text>
        <Box className="block xl:hidden">
          <Text>{ dayjs(item?.created_date).format("DD-MM") }</Text>
        </Box> */}
        </Flex>
      </Card.Section>
      <Card.Section py="xs" px="md">
        { renderGridContent() }
      </Card.Section>
    </Card>
  );
};

export default Record;
