import { useEffect, useContext } from 'react'
import md5 from 'md5'
import { Modal, Button, Input, PasswordInput, Flex, Text, Select, Box } from '@mantine/core';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import UserContext from '../../contexts/Users/userContext';



const UserModal = ({ onOpen, onClose, isOpen, item }) =>
{
  const userContext = useContext(UserContext);
  const { postNewUser, updateUser } = userContext;
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {
      data.password = md5(data.password);
      data.repeat_password = md5(data.repeat_password);
      delete data.repeat_password
      data.status = data.status === 'true' ? true : false
      try {
        if (!item) {
          await postNewUser(data);
        } else {
          data._id = item._id;
          await updateUser(data);
        }
        onClose()
      } catch (error) {
        console.log(error)
      }
    }
  })
  const close = () =>
  {
    formik.resetForm()
    onClose()
  }
  useEffect(() =>
  {
    if (item) {
      formik.setFieldValue('username', item.username);
      formik.setFieldValue('name', item.name);
      formik.setFieldValue('email', item.email);
      formik.setFieldValue('role', item.role);
      formik.setFieldValue('status', item.status ? 'true' : 'false');

    }
  }, [ item ])

  return (
    <>

      <Modal size="lg" opened={ isOpen } onClose={ close } centered title={ <Flex direction="row" gap="xs" px="md" py="xs">
        <Text fz="lg" fw="bold">{ !item?._id ? 'Agregar Usuario' : 'Editar Usuario' }</Text>
      </Flex> }>
        <Box>
          <Flex justify="space-between" px="md" py="xs" gap="md">
            <Input.Wrapper label="Nombre" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Nombre"
                value={ formik.values.name }
                onChange={ formik.handleChange("name") }
              />

              { formik.touched.name && formik.errors.name && <Text c="red" fz="xs">{ formik.errors.name }</Text> }
            </Input.Wrapper>
          </Flex>

          <Flex justify="space-between" px="md" py="xs" gap="md">
            <Input.Wrapper label="Correo" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="email"
                value={ formik.values.email }
                onChange={ formik.handleChange("email") }
              />

              { formik.touched.email && formik.errors.email && <Text c="red" fz="xs">{ formik.errors.email }</Text> }
            </Input.Wrapper>
          </Flex>
          <Flex justify="space-between" px="md" py="xs" gap="md">
            <Input.Wrapper label="Usuario" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="username"
                value={ formik.values.username }
                onChange={ formik.handleChange("username") }
              />

              { formik.touched.username && formik.errors.username && <Text c="red" fz="xs">{ formik.errors.username }</Text> }
            </Input.Wrapper>
          </Flex>
          <Flex justify="space-between" px="md" py="xs" gap="md">
            <Input.Wrapper label="Rol" w="100%">
              <Select placeholder='Selecciona un rol'
                variant="filled"
                size="lg"
                radius="md"
                value={ formik.values.role }
                data={ [ "SUPER_ADMIN", "ADMIN", "USER" ] }
                onChange={ (value) => formik.setFieldValue("role", value) }
              />
              { formik.touched.role && formik.errors.role && <Text c='red' fz={ 12 }>{ formik.errors.role }</Text> }
            </Input.Wrapper>
            <Input.Wrapper label="Estado" w="100%">
              <Select placeholder='Selecciona el estatus'
                variant="filled"
                size="lg"
                radius="md"
                value={ formik.values.status }
                data={ [ {
                  value: "true",
                  label: "Activo",
                }, {
                  value: "false",
                  label: "Inactivo",
                } ] }
                onChange={ (value) => formik.setFieldValue("status", value) }
              />
              { formik.touched.status && formik.errors.status && <Text c='red' fz={ 12 }>{ formik.errors.status }</Text> }
            </Input.Wrapper>
          </Flex>
          <Flex justify="space-between" px="md" py="xs" gap="md">
            <Input.Wrapper label="Password" w="100%">
              <PasswordInput
                variant="filled"
                size="lg"
                radius="md"
                placeholder="password"
                value={ formik.values.password }
                onChange={ formik.handleChange("password") }
              />
              { formik.touched.role && formik.errors.password && <Text c='red' fz={ 12 }>{ formik.errors.password }</Text> }
            </Input.Wrapper>
            <Input.Wrapper label="Repetir password" w="100%">
              <PasswordInput
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Repetir password"
                value={ formik.values.repeat_password }
                onChange={ formik.handleChange("repeat_password") }
              />
              { formik.touched.repeat_password && formik.errors.repeat_password && <Text c='red' fz={ 12 }>{ formik.errors.repeat_password }</Text> }
            </Input.Wrapper>
          </Flex>


          <Flex justify="space-between" gap="md" my="md" mx="md">
            <Button size='lg' fullWidth color='gray' autoContrast variant='light' onClick={ close }>
              Cerrar
            </Button>
            <Button size='lg' fullWidth color="yellow" autoContrast onClick={ () => formik.handleSubmit() }> { !item?._id ? 'Agregar' : 'Actualizar' }</Button>
          </Flex>
        </Box>
      </Modal>
    </>
  )
}

export default UserModal;

function initialValues ()
{
  return {
    name: '',
    email: '',
    username: '',
    password: '',
    repeat_password: '',
    status: true,
    role: ''


  }
}

function validationSchema ()
{
  return {
    name: Yup.string().required('El nombre es requerido'),
    email: Yup.string().required('El correo es requerido'),
    username: Yup.string().required('El usuario es requerido'),
    role: Yup.string().required('El rol es requerido'),
    status: Yup.boolean().oneOf([ true, false ]).required('El estado es requerido'),
    password: Yup.string().required('La contraseña debe de ser mayor a 10 caracteres'),
    repeat_password: Yup.string().required('El password no es igual'),
  }
}
