import React, { useState, useEffect, useMemo } from 'react';
import { currencyFormat } from 'simple-currency-format';
import { Box, Divider, Flex, Title, Card, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';

import BoxSummary from '../components/Dashboard/BoxSummary';
import BoxBank from '../components/Dashboard/BoxBanks';
import Record from '../components/Dashboard/Record';
import RowRenovation from '../components/Dashboard/RowRenovation';

import { get_records, rollback_renovation_customer } from '../utils/apis/records';
import { get_customers } from '../utils/apis/customers';
import { get_renovation_nexttendays } from '../utils/apis/home';
import { getTempTotal } from '../utils/functions/getTotal';
import ConfirmRollback from '../components/Dashboard/ConfirmRollback';

const Dashboard = () =>
{
  const today = useMemo(() => dayjs().format('YYYY-MM-DD'), []);
  const tenDays = useMemo(() => dayjs().add(10, 'day').format('YYYY-MM-DD'), []);
  const [ rollbackData, setRollbackData ] = useState(null);
  const [ openRollBack, setOpenRollBack ] = useState(false);


  const [ data, setData ] = useState({
    itemsCustomers: null,
    itemsPostpago: null,
    itemsPrepago: null,
    totalPostpago: null,
    totalPrepago: null,
    totalAmount: null,
    records: null,
    renovation: null,
    banks: null,
    loadingBanks: true,
  });

  const fetchDashboardData = async () =>
  {
    const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
    const endDate = dayjs().endOf('month').add(1, 'day').format('YYYY-MM-DD');

    const recordsRes = await get_records({
      filter: [ dayjs(startDate).format('DD/MM/YYYY'), dayjs(endDate).format('DD/MM/YYYY') ],
      status: true
    });
    const postpago = recordsRes.records.filter((item) => item.type_contract === 'Postpago');
    const prepago = recordsRes.records.filter((item) => item.type_contract === 'Prepago');

    const customersRes = await get_customers();
    const renovationRes = await get_renovation_nexttendays();
    setData({
      itemsCustomers: customersRes.length,
      itemsPostpago: customersRes.filter((item) => item.type_contract === 'Postpago').length,
      itemsPrepago: customersRes.filter((item) => item.type_contract === 'Prepago').length,
      totalPostpago: getTempTotal(postpago),
      totalPrepago: getTempTotal(prepago),
      totalAmount: getTempTotal(recordsRes.records),
      records: recordsRes.records.reverse().slice(0, 9),
      renovation: renovationRes
        .filter(
          (item) => Date.parse(dayjs(item.limit_date_billing).format('YYYY-MM-DD')) >= Date.parse(today)
        )
        .sort((a, b) => new Date(a.limit_date_billing) - new Date(b.limit_date_billing))
        .slice(0, 9),
      banks: calculateBankAmounts(recordsRes.records),
      loadingBanks: false,
    });
  };

  const renderBanks = (banks) => {
    if (!Array.isArray(banks) || banks.length === 0) {
      return <Skeleton height={80} radius="md" my={4} />;
    }

    // Ordenar bancos alfabéticamente para una presentación consistente
    const sortedBanks = banks.sort((a, b) => a.bank.localeCompare(b.bank));

    return sortedBanks.map((item, index) => (
      <BoxBank
        key={index}
        title={item.bank === "undefined" ? "Sin identificar" : item.bank}
        value={formatCurrency(item.amount)}
        color="gray"
      />
    ));
  };
  useEffect(() =>
  {
    fetchDashboardData();
  }, []);

  const toggleRollback = (item) =>
  {
    setOpenRollBack(true)
    setRollbackData(item)

  }

  const closeRollback = () =>
  {
    setOpenRollBack(false)
    setRollbackData(null)
  }

  const rollBackRenovation = async (item) =>
  {
    const data = {
      _id: item._id,
    }
    await rollback_renovation_customer(data);
    fetchDashboardData();
    closeRollback();
  }
const calculateBankAmounts = (addresses) => {
  const bankAmountMap = addresses.reduce((acc, { type_bank_name, amount }) => {
    const bankName = type_bank_name ? type_bank_name.trim().toLowerCase() : "undefined";
    acc[bankName] = (acc[bankName] || 0) + parseFloat(amount || 0);
    return acc;
  }, {});

  // Agregar bancos con valor 0 si no están presentes
  const predefinedBanks = [
    "banregio",
    "azteca",
    "bbva",
    "hsbc",
    "mercado pago",
    "santander",
    "efectivo",
    "spin",
  ];

  predefinedBanks.forEach((bank) => {
    if (!(bank in bankAmountMap)) {
      bankAmountMap[bank] = 0;
    }
  });

  return Object.entries(bankAmountMap).map(([bank, amount]) => ({ bank, amount }));
};
  return (
    <Box className="w-full gap-4 px-2 h-[100%]">
      <Box className='flex flex-row'>
        <Box className='flex-grow'>
          <Flex justify="flex-start" align="end" gap="xs">
            <Title order={ 1 }>Dashboard</Title>
            <Title order={ 4 }> - { dayjs().locale('es-mx').format('MMMM') }</Title>
          </Flex>
          <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mb-4">
            { renderBoxSummaries(data) }
          </Box>
          <Divider py="md" />
          <Box className='block lg:hidden'>
            <Section title="Bancos">
              { data.loadingBanks ? renderSkeletons(6) : renderBanks(data.banks) }
            </Section>
          </Box>
          <Box className="my-4 grid grid-cols-1 md:grid-cols-2 gap-2">
            <CardSection title="Últimas renovaciones" data={ data.records }>
              { (item) => <Record key={ item._id } item={ item } rollBackRenovation={ toggleRollback } /> }
            </CardSection>
            <CardSection title="Próximos a vencer" data={ data.renovation }>
              { (item) => <RowRenovation key={ item._id } item={ item } today={ today } tenDays={ tenDays } /> }
            </CardSection>
          </Box>
        </Box>
        <Box className='border-l ml-2 hidden lg:block'>
          <SectionBanks title="Bancos">
            { data.loadingBanks ? renderSkeletons(6) : renderBanks(data.banks) }
          </SectionBanks>
        </Box>
      </Box>
      <ConfirmRollback show={ openRollBack } onHide={ closeRollback } item={ rollbackData } onConfirm={ rollBackRenovation } />
    </Box>
  );
};

const renderBoxSummaries = (data) =>
{
  const summaries = [
    { title: 'Usuario postpago', value: data.itemsPostpago },
    { title: 'Usuarios prepago', value: data.itemsPrepago },
    { title: 'Total usuarios', value: data.itemsCustomers },
    { title: 'Total Postpago', value: data.totalPostpago, currency: true },
    { title: 'Total Prepago', value: data.totalPrepago, currency: true },
    { title: 'Total de ingresos', value: data.totalAmount, currency: true },
  ];
  return summaries.map((item, index) =>
    item.value !== undefined && item.value !== null ? (
      <BoxSummary
        key={ index }
        title={ item.title }
        value={ item.currency ? formatCurrency(item.value) : item.value }
        color="gray"
      />
    ) : (
      <Skeleton key={ index } height={ 80 } radius="md" />
    )
  );
};

const renderSkeletons = (count) => Array.from({ length: count }, (_, i) => <Skeleton key={ i } height={ 80 } radius="md" my={ 4 } />);
const formatCurrency = (amount) => `${ currencyFormat(amount, 'es-MX', 'MXN', 2) } mxn`;
const Section = ({ title, children }) => (
  <Box>
    <Title order={ 4 }>{ title }</Title>
    <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">{ children }</section>
  </Box>
);

const SectionBanks = ({ title, children }) => (
  <Box className='w-full lg:w-64 mx-2 gap-2 hidden lg:block'>
    <Title order={ 4 }>{ title }</Title>
    <section className="grid grid-cols-1 gap-2">{ children }</section>
  </Box>
);

const CardSection = ({ title, data, children }) => (
  <Card shadow="sm" padding="xl" radius="lg" withBorder>
    <Title order={ 4 }>{ title }</Title>
    { data
      ? data.map((item) => (
        <Card.Section key={ item._id } py="xs" className='gap-2'>
          { children(item) }
        </Card.Section>
      ))
      : renderSkeletons(6) }
  </Card>
);

export default Dashboard;
