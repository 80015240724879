import { useState, useEffect, useContext } from 'react'


import { Modal, Button, Flex, Text, Table, Badge, Skeleton } from '@mantine/core';


const ModalViewRecords = ({ onOpen, onClose, isOpen, num_account, records }) =>
{


  const close = () =>
  {
    onClose()
  }




  return (
    <>

      <Modal size="lg" opened={ isOpen } onClose={ close } title={ `Historial de pago no. cuenta ${ num_account }` }>
        <Table striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Fecha</Table.Th>
              <Table.Th>Monto</Table.Th>
              <Table.Th>Banco</Table.Th>
              <Table.Th>Referencia</Table.Th>
              <Table.Th>Estado del pago</Table.Th>
            </Table.Tr>
          </Table.Thead>
          { records && records.length > 0 ? (

            <Table.Tbody>
              { records && records.map((record) => (

                <Table.Tr>
                  <Table.Td>{ record?.created_date }</Table.Td>
                  <Table.Td>$ { Number(record?.amount).toFixed(2) }</Table.Td>
                  <Table.Td>{ record?.type_bank_name }</Table.Td>
                  <Table.Td>{ record?.payment_reference }</Table.Td>
                  <Table.Td>{ !record?.status ? <Badge color="red" >Pago cancelado</Badge> : <Badge color='gray'>Pago acreditado</Badge> }</Table.Td>
                </Table.Tr>

              )) }
            </Table.Tbody>

          ) : (<>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td colSpan={ 5 }>
                  <Skeleton height={ 16 } radius="md" my="xs" />
                </Table.Td>

              </Table.Tr>
              <Table.Tr>
                <Table.Td colSpan={ 5 }>
                  <Skeleton height={ 16 } radius="md" my="xs" />
                </Table.Td>

              </Table.Tr>
              <Table.Tr>
                <Table.Td colSpan={ 5 }>
                  <Skeleton height={ 16 } radius="md" my="xs" />
                </Table.Td>

              </Table.Tr>
              <Table.Tr>
                <Table.Td colSpan={ 5 }>
                  <Skeleton height={ 16 } radius="md" my="xs" />
                </Table.Td>

              </Table.Tr>
              <Table.Tr>
                <Table.Td colSpan={ 5 }>
                  <Skeleton height={ 16 } radius="md" my="xs" />
                </Table.Td>

              </Table.Tr>
              <Table.Tr>
                <Table.Td colSpan={ 5 }>
                  <Skeleton height={ 16 } radius="md" my="xs" />
                </Table.Td>

              </Table.Tr>
            </Table.Tbody>
          </>) }
        </Table>
        <Flex justify="space-between" gap="md" my="lg">
          <Button fullWidth color='gray' autoContrast variant='light' onClick={ close }>
            Cerrar
          </Button>
        </Flex>

      </Modal>
    </>
  )
}

export default ModalViewRecords;
