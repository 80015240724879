import React, { useContext } from "react";
import { Box, Divider, Avatar, Text, NavLink, Image } from "@mantine/core";
import { useLocation } from "react-router-dom";
import AuthContext from "../../contexts/Auth/authContext";
import {
  HiOutlineDocumentReport,
  HiOutlineUsers,
  HiOutlineIdentification,
  HiOutlineReceiptTax,
} from "react-icons/hi";
import { BsInboxes } from "react-icons/bs";
import { MdOutlineDashboard } from "react-icons/md";
import Logo from "../../assets/logo.png";
import MenuItem from "./MenuItem";

const Sidebar = ({ toggleSidebar }) => {
  const { pathname } = useLocation();
  const { username } = useContext(AuthContext);

  const handleToggleSidebar = () => {
    if (window.innerWidth < 768) {
      toggleSidebar();
    }
  };

  // Definición de las opciones de menú
  const menuItems = [
    {
      path: "/dashboard",
      text: "Dashboard",
      icon: <MdOutlineDashboard className="text-md lg:text-lg" />,
    },
    {
      path: "/plans",
      text: "Planes",
      icon: <HiOutlineDocumentReport className="text-md lg:text-lg" />,
    },
    {
      path: "/reports",
      text: "Transacciones",
      icon: <HiOutlineReceiptTax className="text-md lg:text-lg" />,
    },
    {
      path: "/customers",
      text: "Clientes",
      icon: <HiOutlineIdentification className="text-md lg:text-lg" />,
    },
    {
      path: "/users",
      text: "Usuarios",
      icon: <HiOutlineUsers className="text-md lg:text-lg" />,
    },
  ];

  // Submenú
  const subMenu = {
    label: "Vencimiento",
    icon: <BsInboxes className="text-md lg:text-lg" />,
    children: [
      {
        path: "/renovation/ten-days",
        text: "10 días",
      },
      {
        path: "/renovation/thirty-days",
        text: "30 días",
      },
    ],
  };

  return (
    <>
      {/* Logo */}
      <Box className="hidden md:flex flex-row items-center justify-center gap-2 py-1 px-2">
        <div className="rounded-full bg-gray-100 border border-gray-800 w-16">
          <Image
            className="w-full h-auto"
            src={Logo}
            alt="logo"
            title="logo gc-com"
          />
        </div>
      </Box>

      <Divider className="my-2 hidden md:block" />

      {/* Usuario */}
      <Box className="flex flex-row items-center gap-2 py-4 px-4">
        <Avatar radius="xl" />
        {username && (
          <Text className="font-normal text-md mx-1">{username.replaceAll('"', "")}</Text>
        )}
      </Box>

      {/* Menú */}
      <Box className="flex flex-col items-stretch gap-2 px-2">
        {menuItems.map((item) => (
          <MenuItem
            key={item.path}
            onClick={handleToggleSidebar}
            path={item.path}
            text={item.text}
            icon={item.icon}
            active={pathname === item.path}
          />
        ))}

        {/* Submenú */}
        <NavLink
          label={subMenu.label}
          leftSection={subMenu.icon}
          childrenOffset={28}
        >
          {subMenu.children.map((child) => (
            <MenuItem
              key={child.path}
              onClick={handleToggleSidebar}
              path={child.path}
              text={child.text}
              active={pathname === child.path}
            />
          ))}
        </NavLink>
      </Box>
    </>
  );
};

export default Sidebar;
