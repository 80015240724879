/* eslint-disable import/no-anonymous-default-export */
import { useReducer } from 'react';
import planReducer from './planReducer';
import planContext from './planContext'
import { get_plans, post_plans, delete_plans, update_plan } from '../../utils/apis/plans';
import {
    LIST_PLANS,
    ADD_PLAN,
    DELETE_PLAN,
    UPDATE_PLAN
} from '../types';

const PlanState = props => {
    const initialState = {
        list: null,
        loading: false,
        message: null
    }

    const [state, dispatch] = useReducer(planReducer, initialState);

    const getPlanList = async (data) => {
        try {
            const res = await get_plans(data)

            if (res.length !== 0) {

                dispatch({
                    type: LIST_PLANS,
                    payload: res
                })
            } else {
                
            }
        } catch (error) {

           
        }


    }
    const postNewPlan = async (data)=>{
        try {
            const res = await post_plans(data)

            if (res.inserted_id) {

                dispatch({
                    type: ADD_PLAN
                })
            } else {
                
            }
        } catch (error) {

           
        }
    }

    const updatePlan = async (data)=>{
        try {
            await update_plan(data)
            dispatch({
                type: UPDATE_PLAN
            })
        } catch (error) {

           
        }
    }
    const deletePlan = async (data)=>{
        try {
            await delete_plans(data)
            dispatch({
                type: DELETE_PLAN
            })
        } catch (error) {

           
        }
    }

    return (
        <planContext.Provider
            value={{
                list: state.list,
                loading: state.loading,
                message: state.message,
                getPlanList,
                postNewPlan,
                updatePlan,
                deletePlan
            }}
        >
            {
                props.children
            }
        </planContext.Provider>
    )

}


export default PlanState