import { Modal, Button, Flex, Text } from '@mantine/core';


const ModalConfirm = ({ onOpen, onClose, isOpen, item, remove }) =>
{
  const close = () =>
  {
    onClose()
  }
  return (
    <>

      <Modal size="lg" opened={ isOpen } onClose={ close } title={ `Confirmar eliminar cliente` }>
        <Text>¿Estás seguro de que deseas hacer eliminar al cliente?</Text>
        { item && (
          <>
            <Text fz="lg" my="lg">
              Se va a eliminar a <b>{ item?.full_name ? item.full_name : 'N/A'}</b>  con el numero de cuenta <b>{ item?.num_account }</b>.
            </Text>

            <Text fz="lg" my="lg" fw="bold">
              ¡Este proceso no se puede deshacer!.
            </Text>

          </>
        ) }
        <Flex justify="space-between" gap="md" my="lg">
          <Button fullWidth color='gray' autoContrast variant='light' onClick={ close }>
            Cerrar
          </Button>
          <Button fullWidth color="yellow" autoContrast onClick={ () => remove({ _id: item._id }) }>Confirmar</Button>
        </Flex>

      </Modal>
    </>
  )
}

export default ModalConfirm;
