import React from 'react';
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { Card, Flex, Text, Box, Badge, Grid } from '@mantine/core'

const RowRenovation = ({ item, renovar, today, tenDays }) =>
{
  const hoy = Date.parse(today);
  const limit_date_billing = Date.parse(dayjs(item?.limit_date_billing).format('YYYY-MM-DD'))

  return (
    <Card shadow="sm" padding="xl" radius="md" withBorder  mx="md">
      <Card.Section py="xs">
        <Grid gap='lg' p="xs" align='center'>
          <Grid.Col span={{base:12, xl:4}} className='flex flex-row items-start justify-start w-48'>
            { hoy === limit_date_billing && <Badge className='p-1 text-xs text-center' color='orange' autoContrast>Hoy Vence</Badge> }
            { hoy < limit_date_billing && <Badge className='p-1 text-xs text-center' color='yellow' autoContrast>Vencera pronto</Badge> }

          </Grid.Col>
          <Grid.Col span={{base:6, xl:4}} className='flex flex-row items-center justify-start w-48'>
            <Text className='text-center'>{ dayjs(item?.limit_date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase() }</Text>
          </Grid.Col>

          <Grid.Col span={{base:6, xl:4}} className='flex flex-row items-center justify-start w-48'>
            <Text className='text-right'>{ item.num_account }</Text>
          </Grid.Col>
        </Grid>

      </Card.Section>

    </Card>
  )
}

export default RowRenovation
