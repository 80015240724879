import React from 'react'
import { Spinner } from '@chakra-ui/react'

const Loading = () => {
  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <Spinner
        thickness='4px'
        speed='0.65s'
        size='xl'
      />
    </div>
  )
}

export default Loading
