import React from 'react';
import { Modal, Button, Flex, Text } from '@mantine/core';

function ConfirmRollback({ item, show, onHide, onConfirm }) {
  return (
    <Modal opened={show} onClose={onHide} title="Confirmar revertir" centered>
      <Text>¿Estás seguro de que deseas hacer revertir del siguiente registro?</Text>
     {item && (
      <Text fz="lg"  my="lg">
             Se va a cancelar la renovación de la cuenta <b>{ item?.num_account }</b> por un monto de <b>$ {Number(item.amount).toFixed(2) }</b>.
            </Text>

     )}
      <Flex justify="space-between" gap="md" mt="lg">
        <Button fullWidth color='gray' autoContrast variant='light'  onClick={onHide}>
          Cancelar
        </Button>
        <Button fullWidth color="yellow" autoContrast  onClick={onConfirm}>
          Confirmar
        </Button>
      </Flex>
    </Modal>
  );
}

export default ConfirmRollback;
