const token = localStorage.getItem('token')

export const get_renovation_month = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/get_renovation_month`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json',
        "security-token":token
      },
      // body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const get_renovation_nexttendays = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/get_renovation_nexttendays`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json',
        "security-token":token
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}


export const renovation_customer = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/renovation_customer`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json',
        "security-token":token
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

