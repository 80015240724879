export const postLogin = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json'
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}