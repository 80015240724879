import React, { useState } from "react";
import { currencyFormat } from "simple-currency-format";
import {
  Card,
  Text,
  ActionIcon,
  Grid,
  Divider,
  Box,
  Flex,
  Image,
} from "@mantine/core";
import { HiChevronUp, HiChevronDown } from "react-icons/hi";
import dayjs from "dayjs";

import Banregio from "../../assets/banks/banregio.webp";
import Azteca from "../../assets/banks/azteca.webp";
import Bbva from "../../assets/banks/bbva2.png";
import Hsbc from "../../assets/banks/hsbc.png";
import MercadoPago from "../../assets/banks/mercado-pago.webp";
import Santander from "../../assets/banks/santander-logo-1.webp";
import Efectivo from "../../assets/banks/efectivo.png";
import Spin from "../../assets/banks/spin.png";

const bankImages = {
  "Mercado Pago": MercadoPago,
  BBVA: Bbva,
  Santander: Santander,
  Hsbc: Hsbc,
  Banregio: Banregio,
  Azteca: Azteca,
  Efectivo: Efectivo,
  Spin: Spin,
};

const Record = ({ item, dashboard = false }) => {
  const [show, setShow] = useState(false);

  const toggleDetails = () => setShow((prev) => !prev);

  const renderCustomerInfo = () => (
    <Grid>
      <Grid.Col span={{ base: 12, lg: 6 }}>
        <Box>
          <Text size="sm" weight={600} mt="xs">Información del cliente</Text>
          <Grid>
            <InfoRow label="Nombre:" value={item.full_name} />
            <InfoRow label="Correo:" value={item.email} />
          </Grid>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ base: 12, lg: 6 }}>
        <Text size="sm" weight={600} mt="xs">Información de la cuenta</Text>
        <Grid>
          <InfoRow label="Tel:" value={item.phone} />
          <InfoRow label="Cel:" value={item.cellphone} />
          <InfoRow label="Referencia de pago:" value={item.payment_reference} />
        </Grid>
      </Grid.Col>
    </Grid>
  );

  return (
    <Card shadow="md" padding="xl" radius="lg" withBorder>
      <Card.Section pt="xs" px="md">
        <Flex align="center" justify="stretch">
          <Box className="w-full">
            <Box px="md" pt="sm">
              <Flex justify="space-between" align="center">
                <Box className="w-14 h-12">
                  <Image
                    src={bankImages[item.type_bank_name]}
                    alt={item.type_bank_name}
                    title={item.type_bank_name}
                    className="w-full h-auto object-contain"
                  />
                </Box>
                <ActionIcon
                  variant="light"
                  color="gray"
                  radius="md"
                  size="lg"
                  onClick={toggleDetails}
                >
                  {show ? <HiChevronUp /> : <HiChevronDown />}
                </ActionIcon>
              </Flex>
            </Box>
            <Box p="md">
              <Grid>
                <Grid.Col span={{ base: 6, md: 3 }}>
                  <Text>{dayjs(item?.created_date).format("DD/MMM/YYYY")}</Text>
                </Grid.Col>
                <Grid.Col span={{ base: 6, md: 3 }}>
                  <Text>{item.type_contract}</Text>
                </Grid.Col>
                <Grid.Col span={{ base: 6, md: 3 }}>
                  <Text>{item.num_account}</Text>
                </Grid.Col>
                <Grid.Col span={{ base: 6, md: 3 }}>
                  <Text align="right" weight={600}>
                    {currencyFormat(item.amount, "es-MX", "MXN", 2)}
                  </Text>
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
        </Flex>
      </Card.Section>
      {show && (
        <>
          <Divider />
          <Card.Section px="md" py="sm">
            <Text size="sm" weight={600} mt="xs">Comentarios:</Text>
            <Text size="sm">{item.comments}</Text>
          </Card.Section>
          <Divider />
          <Card.Section px="md" py="sm">{renderCustomerInfo()}</Card.Section>
        </>
      )}
    </Card>
  );
};

const InfoRow = ({ label, value }) => (
  <Box className="flex flex-row gap-2">
    <Text size="sm" weight={400}>{label}</Text>
    <Text size="sm" weight={400}>{value}</Text>
  </Box>
);

export default Record;
