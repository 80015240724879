/* eslint-disable import/no-anonymous-default-export */
import { useReducer } from 'react';
import customerReducer from './customerReducer';
import customerContext from './customerContext'
import { get_customers, post_customer, delete_customer, update_customer } from '../../utils/apis/customers';
import {
    LIST_CUSTOMERS,
    ADD_CUSTOMER,
    DELETE_CUSTOMER,
    UPDATE_CUSTOMER
} from '../types';

const CustomerState = props => {
    const initialState = {
        list: null,
        loading: false,
        message: null
    }

    const [state, dispatch] = useReducer(customerReducer, initialState);

    const getCustomersList = async (data) => {
        try {
            const res = await get_customers(data)
            if (res.length !== 0) {

                dispatch({
                    type: LIST_CUSTOMERS,
                    payload: res
                })
            } else {
                
            }
        } catch (error) {

           
        }


    }
    const postNewCustomer = async (data)=>{
        try {
            const res = await post_customer(data)

            if (res.inserted_id) {

                dispatch({
                    type: ADD_CUSTOMER
                })
            } else {
                
            }
        } catch (error) {

           
        }
    }

    const updateCustomer = async (data)=>{
        try {
            await update_customer(data)
            dispatch({
                type: UPDATE_CUSTOMER
            })
        } catch (error) {

           
        }
    }

    const deleteCustomer = async (data)=>{
        try {
            await delete_customer(data)
            dispatch({
                type: DELETE_CUSTOMER
            })
        } catch (error) {

           
        }
    }

    return (
        <customerContext.Provider
            value={{
                list: state.list,
                loading: state.loading,
                message: state.message,
                getCustomersList,
                postNewCustomer,
                deleteCustomer,
                updateCustomer
            }}
        >
            {
                props.children
            }
        </customerContext.Provider>
    )

}


export default CustomerState