/* eslint-disable import/no-anonymous-default-export */
import {
  LIST_PLANS,
  ADD_PLAN,
  DELETE_PLAN,
  UPDATE_PLAN
} from '../types';



export default (state, action) => {
  //console.log(action)
  switch (action.type) {

    case LIST_PLANS:
      return {
        ...state,
        list: action.payload,
        loading:false,
        message:null
      }
    case ADD_PLAN:
    case DELETE_PLAN:
    case UPDATE_PLAN:
      return {
        ...state,
        loading:true
      }

    default:
      console.log('default')
      return state;

  }
}