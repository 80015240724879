import React from 'react'
import IconError from '../../assets/404.png'
const NotFound = () => {
  return (
    <div className='bg-gray-900 w-full gap-1 h-[100vh] overflow-x-auto'>
      <div className='mx-2'>
        <div class="flex flex-col items-center justify-center h-[100vh]">
        <span className='text-white text-3xl font-semibold font-sans'>Pagina no encontrado</span>
          <img className='w-3/12 h-auto' src={IconError} alt="Error" title='Error'/>
       
        </div>
      </div>
    </div>
  )
}

export default NotFound