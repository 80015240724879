import { useState, useEffect } from "react";

const useIsMobileOrTablet = () => {
  const isMobileOrTabletView = () => window.matchMedia("(max-width: 768px)").matches;
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(isMobileOrTabletView());

  useEffect(() => {
    const handleResize = () => setIsMobileOrTablet(isMobileOrTabletView());

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobileOrTablet;
};

export default useIsMobileOrTablet;
