import React from 'react'
import { NavLink, Tooltip } from '@mantine/core';
const MenuItem = ({ active, icon, path, text, onClick }) =>
{
  return (
    <Tooltip label={ text } position='bottom'>
      <NavLink
        onClick={ onClick }
        variant="filled"
        autoContrast
        className="rounded-lg"
        color="yellow.6"
        active={ active }
        href={ `#${ path }` }
        label={ text }
        leftSection={ icon }

      />
    </Tooltip>
  )
}

export default MenuItem
