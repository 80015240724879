export const INDEX = '/'
export const LOGIN = '/login'
export const SIGNIN = 'signin'
export const RESTORE_PASSWORD = 'restore_password'
export const HOME = '/home'
export const DASHBOARD = '/dashboard'
export const CUSTOMERS = '/customers'
export const PLANS = '/plans'
export const USUARIOS = 'ususarios'
export const SEARCH = 'search'
