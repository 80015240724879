export const get_records = async (data)=>{
  try {
    const url = `${process.env.REACT_APP_API_URL}/get_records`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const rollback_renovation_customer = async (data)=>{
  try {
    const url = `${process.env.REACT_APP_API_URL}/rollback_renovation_customer`;
    const params = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access": "core_gccom",
        'Accept': 'application/json',
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}



/**
 * TODO: Para usar el getrecords para listar el historial de pago de un cliente usar el num_account
 */

/**
 * TODO: rollback_renovation_customer
 */

/**
 * TODO:get_records usa la variable status para filtrar los registros de pago en true para los que son buenos y false para los que se hicieron rollback
 */
