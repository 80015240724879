import React from 'react'
import { Card, Text, Title } from '@mantine/core'
import Loading from '../Loading/Loading'

const BoxSummary = ({ title, value, color }) =>
{
  return (
    <Card shadow="sm" padding="xl" radius="md" withBorder>
      <Card.Section className='px-4 py-4'>
        <Text  size="md">{ title }</Text>
        { value ?
          <Title order={4}  className='text-right mx-2'>{ value }</Title>
          :
          <Loading /> }
      </Card.Section>
    </Card>
  )
}

export default BoxSummary
