import React from 'react'
import { HiOutlineTrash, HiOutlinePencilAlt, HiOutlineEye } from "react-icons/hi";
import dayjs from 'dayjs'
import 'dayjs/locale/es'

import { Card, Table, Tooltip, } from '@mantine/core';
import CustomerRow from './CustomerRow';

const CustomersTable = ({ items, onOpen, openConfirm, openRecordModal }) =>
{
  return (
    <Card shadow="sm" p="lg" radius="xl" withBorder mx="md" className='w-full'>
      <Table.ScrollContainer minWidth={ 500 }>
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr className=' rounded-lg '>

              <Table.Th># Cuenta</Table.Th>
              <Table.Th align="left">Nombre</Table.Th>
              <Table.Th align="center">Fecha de corte</Table.Th>
              <Table.Th align="center">Fecha de Limite</Table.Th>

              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            { items && items.map((item) => (
              <CustomerRow item={ item } onOpen={ onOpen } openConfirm={ openConfirm } openRecordModal={openRecordModal}/>
            )) }
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Card>
  )
}

export default CustomersTable
